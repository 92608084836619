import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { networks } from "./../vars";

const DropDown = ({ options, setValue, value }) => {
  const [dropDownActive, setDropDownActive] = useState(false);

  const netwroksObj = {};
  networks.forEach((network) => {
    netwroksObj[network.value] = network.name;
  });

  useEffect(() => {
    const url = window.location.href;
    const network = url.split("/")[4];
    networks.forEach((networkObj) => {
      if (networkObj.name === network) {
        setValue(networkObj.value);
      }
    });
  }, [value]);

  const navigate = useNavigate();

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <button
        style={{
          fontSize: "1em",
          width: "6em",
          padding: "0.5em",
          height: "2em",
          backgroundColor: "#f01778",
          color: "white",
          borderRadius: "0.25em",
          zIndex: "2",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "nowrap",
        }}
        onClick={() => setDropDownActive(!dropDownActive)}
      >
        {netwroksObj[value]}
        <ArrowDropUpIcon
          style={
            dropDownActive
              ? {
                  transform: "rotate(180deg)",
                  transition: "0.5s",
                }
              : {
                  transform: "rotate(0deg)",
                  transition: "0.5s",
                }
          }
        />
      </button>
      <div
        style={{
          width: "6em",
          padding: "0.5em",
          backdropFilter: "blur(5px)",
        }}
        className={`dropdown ${dropDownActive ? "active" : ""}`}
      >
        {options.map((option) => {
          return (
            <div
              style={{
                padding: "0.5em",
                cursor: "pointer",
              }}
              key={option.value}
              className={value === option.value ? " active" : ""}
              onClick={() => {
                setValue(option.value);
                navigate(`/PayrollManagement/${option.name}`);
                setDropDownActive(false);
              }}
            >
              {option.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DropDown;
