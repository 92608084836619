import {
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  IconButton,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assest/images/logo-with-text.png";
import { pages } from "./../../vars";
import LogoutOutIcon from "@mui/icons-material/LogoutOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import SessionManger from "./sessionManger";

const Sidebar = ({ open, setOpen, screenWidth, network, setNetwork }) => {
  const smallScreen = screenWidth < 600;

  const navigate = useNavigate();
  const location = useLocation().pathname;
  const buttonsStyle = {
    textDecoration: "none",
    color: "gray",
  };

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "#1D1933",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "4vh",
        padding: "4vh 0",
        overflowY: "auto",
        fontSize: "0.8em",
        width: smallScreen ? "100%" : "300px",
        transform: open
          ? "translateX(0)"
          : smallScreen
          ? "translateX(-100%)"
          : "translateX(0px)",
        transition: "transform 0.3s ease-in-out",
        position: smallScreen ? "fixed" : "relative",
        top: 0,
        left: 0,
        zIndex: 3,
      }}
    >
      <Link
        to="/"
        style={{
          width: "25%",
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{
            width: "100%",
          }}
        />
      </Link>
      <SessionManger network={network} setNetwork={setNetwork} />
      <List>
        {smallScreen && (
          <ListItem
            style={{
              fontSize: "1.5em",
              textDecoration: "none",
              display: "grid",
              placeItems: "center",
            }}
          >
            <ListItemButton onClick={() => setOpen(!open)}>
              <MenuIcon />
            </ListItemButton>
          </ListItem>
        )}
        {pages.map((page) => {
          return (
            <Link
              key={page.name}
              to={page.path}
              style={buttonsStyle}
              className={location === page.path ? "active" : ""}
            >
              <ListItem
                onClick={() => {
                  setOpen(!open);
                }}
                className={location === page.path ? "before" : ""}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <page.icon
                      style={buttonsStyle}
                      className={location === page.path ? "active" : ""}
                    />
                  </ListItemIcon>
                  {page.name}
                </ListItemButton>
              </ListItem>
            </Link>
          );
        })}
      </List>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0 2vh",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: ".5em",
            }}
          >
            <img
              src="https://cdn.discordapp.com/avatars/930562072700411925/bae29a66162a297c253ab4f9775f5635.webp?size=128"
              alt="profile"
              style={{
                width: "15%",
                borderRadius: "50%",
              }}
            />
            <span
              style={{
                fontSize: ".8em",
              }}
            >
              Aptos Legend
            </span>
          </div>
          <IconButton
            sx={{
              color: "white",
            }}
            onClick={() => {
              localStorage.removeItem("session");
              navigate("/login");
            }}
          >
            <LogoutOutIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
