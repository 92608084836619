import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login/login";
import Home from "./pages/dashboard/home";
import Dashboard from "./pages/dashboard/views/dashboard";
import PayrollManagement from "./pages/dashboard/views/payrollManagement/payrollManagement";
import EmployeeManegment from "./pages/dashboard/views/employeeManegment";
import Finances from "./pages/dashboard/views/finances";
import TimeTracking from "./pages/dashboard/views/timeTracking";
import EmployeeSelfService from "./pages/dashboard/views/employeeSelfService";
import TeamCalender from "./pages/dashboard/views/teamCalender";
import Notification from "./pages/dashboard/views/notification";
import SchaduledSessions from "./pages/dashboard/views/payrollManagement/shared/schaduledSessions";
import "./styles/App.css";
import { networks } from "./vars";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="Dashboard" element={<Dashboard />} />
            <Route path="PayrollManagement" element={<PayrollManagement />}>
              {networks.map((network) => {
                return (
                  <Route
                    key={network.name}
                    path={network.name}
                    element={<SchaduledSessions />}
                  />
                );
              })}
            </Route>
            <Route path="EmployeeManagement" element={<EmployeeManegment />} />
            <Route path="Finances" element={<Finances />} />
            <Route path="TimeTracking" element={<TimeTracking />} />
            <Route
              path="EmployeeSelfService"
              element={<EmployeeSelfService />}
            />
            <Route path="TeamCalender" element={<TeamCalender />} />
            <Route path="Notification" element={<Notification />} />
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
