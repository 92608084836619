const Preview = ({ preview, fileData }) => {
  if (!preview) return null;
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "1em",
      }}
    >
      {fileData.map((colum, indexC) => {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              paddingBottom: ".5em",
              borderBottom: "2px solid black",
            }}
            key={indexC}
          >
            {colum.map((row, indexR) => {
              return (
                <div
                  style={{
                    width: `${100 / colum.length}%`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    wordBreak: "break-all",
                  }}
                  key={`${indexC} ${indexR}`}
                >
                  {row}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Preview;
