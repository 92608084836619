import { useState, useEffect } from "react";
import DropDown from "../../../../utils/dropdown";
import UploadSVG from "./shared/uploadSVG";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet, useOutletContext } from "react-router";
import ProgressScreen from "./shared/progressScreen";
import Schadule from "./shared/schadule";
import { networks } from "./../../../../vars";
import { useLayoutEffect } from "react";

const PayrollManagement = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [sideBarOpen, setSideBarOpen, network, setNetwork] = useOutletContext();
  const [file, setFile] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [onGoingRequest, setOnGoingRequest] = useState(false);

  if (file === null && processing === true) {
    setProcessing(false);
  }
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const smallScreen = screenWidth < 600;

  useLayoutEffect(() => {
    const main = document.getElementsByClassName("main-screen");
    if (network === "network") {
      main[0].onscroll = () => {
        main[0].scroll(0, 0);
      };
    } else {
      main[0].onscroll = () => {};
    }
  }, [network]);

  return (
    <div>
      {processing && (
        <ProgressScreen
          file={file}
          setProcessing={setProcessing}
          setOnGoingRequest={setOnGoingRequest}
          onGoingRequest={onGoingRequest}
          network={network}
        />
      )}
      {network === "network" && (
        <div className="gray-screen">
          <h2
            style={{
              backgroundColor: "#1D1933",
              borderBottom: ".1em red solid",
              borderTop: ".1em red solid",
              padding: "1em",
              borderRadius: ".5em",
            }}
          >
            Please select a network to continue
          </h2>
          <DropDown options={networks} setValue={setNetwork} value={network} />
        </div>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "1em",
        }}
      >
        {smallScreen && (
          <div
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
            <IconButton
              sx={{
                color: "white",
              }}
              onClick={() => setSideBarOpen(!sideBarOpen)}
            >
              <MenuIcon />
            </IconButton>
          </div>
        )}
        <h4>Payroll Management</h4>
        <DropDown options={networks} setValue={setNetwork} value={network} />
      </div>
      <UploadSVG
        file={file}
        setFile={setFile}
        screenWidth={screenWidth}
        setProcessing={setProcessing}
        onGoingRequest={onGoingRequest}
      />
      <Schadule file={file} screenWidth={screenWidth} network={network} />
      <Outlet context={[network]} />
    </div>
  );
};

export default PayrollManagement;
