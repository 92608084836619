import { useState, useEffect } from "react";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import ImageIcon from "@mui/icons-material/Image";
import { IconButton } from "@mui/material";
import { api } from "../../../../../vars";
import convertTZ from "../../../../../utils/tzConverter";
import LoadingButton from "@mui/lab/LoadingButton";

const ScheduledSessionManger = ({
  session,
  sessions,
  setSessions,
  network,
}) => {
  const [results, setResults] = useState("");
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState("");

  const getResults = async (session) => {
    setLoading(true);
    let results = "";

    const header = {
      "X-Request-ID": session,
    };
    try {
      const res = await axios.get(api + `/${network}/prgs/`, {
        headers: header,
      });
      console.log(res.data);
      setResults(res.data.progress);
      results = res.data.progress;
    } catch (error) {
      if (error.response.data.error) {
        setResults(error.response.data.error);
        results = error.response.data.error;
      } else if (error.response.data.progress) {
        setResults(error.response.data.progress);
        results = error.response.data.progress;
        setImg(error.response.data.err_img);
      } else {
        setResults("Error Getting Results");
        results = "remove";
      }
    }
    if (results.includes("complete")) {
      setLoading(false);
    } else if (results === "error") {
      setResults("Error Occured, Check the Image for more details");
      setLoading(false);
    } else if (results === "session expired") {
      setResults("Session expired, removing session");
      setLoading(false);
      setTimeout(() => {
        const newSessions = sessions.filter((item) => item.id !== session);
        localStorage.setItem(
          `schadule-${network}`,
          JSON.stringify(newSessions)
        );
        setSessions(newSessions);
      }, 5000);
    } else if (results === "remove") {
      setLoading(false);
      setResults("Unknown Error Occured, removing schedule");
      setTimeout(() => {
        const newSessions = sessions.filter((item) => item.id !== session);
        localStorage.setItem(
          `schadule-${network}`,
          JSON.stringify(newSessions)
        );
        setSessions(newSessions);
      }, 5000);
    } else {
      setTimeout(() => {
        getResults(session);
      }, 5000);
    }
  };

  useEffect(() => {
    const timeNow = convertTZ(new Date()).getTime();

    const sessionTime = parseInt(session.timeLeft);
    const timeDiffrance = sessionTime - timeNow;
    setTimeout(() => {
      getResults(session.id);
    }, timeDiffrance);
  }, []);

  return (
    <div
      style={{
        width: "40%",
      }}
    >
      <div
        style={{
          border: ".2em solid #bb46e3",
          padding: "0.5em",
          borderRadius: "0.5em",
          margin: "0.5em",
          width: "100%",
        }}
      >
        Time : {session.time}
      </div>
      <div
        style={{
          border: ".2em solid #bb46e3",
          padding: "0.5em",
          borderRadius: "0.5em",
          margin: "0.5em",
          width: "100%",
        }}
      >
        Session Results : {results}
        {img && (
          <a href={api + img} alt="err">
            <IconButton>
              <ImageIcon
                sx={{
                  color: "#bb46e3",
                }}
              />
            </IconButton>
          </a>
        )}
        <LoadingButton
          className="loading-btn"
          loading={loading}
          size="large"
        ></LoadingButton>
      </div>
      <IconButton
        onClick={async () => {
          setLoading(true);
          try {
            const header = {
              "X-Request-ID": session.id,
            };
            const requestOption = {
              method: "POST",
              headers: header,
              url: api + `/${network}/revoke/`,
            };
            const removedSession = await axios.request(requestOption);
            setResults(removedSession.data.status);
            setTimeout(() => {
              const newSessions = sessions.filter(
                (item) => item.id !== session.id
              );
              setSessions(newSessions);
              localStorage.setItem(
                `schadule-${network}`,
                JSON.stringify(newSessions)
              );
            }, 5000);
            setLoading(false);
          } catch (err) {
            console.log(err);
            setResults("Could not remove session, Please try again later");
            setLoading(false);
          }
        }}
      >
        <ClearIcon
          sx={{
            color: "#bb46e3",
          }}
        />
      </IconButton>
    </div>
  );
};

export default ScheduledSessionManger;
