import { Outlet } from "react-router";
import Sidebar from "./sidebar";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
const Home = () => {
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [network, setNetwork] = useState("network");
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    const logedIn = localStorage.getItem("session");
    if (!logedIn) {
      navigate("/login");
    }
  }, []);
  return (
    <main
      style={{
        display: "flex",
      }}
    >
      <Sidebar
        open={sideBarOpen}
        setOpen={setSideBarOpen}
        screenWidth={screenWidth}
        network={network}
        setNetwork={setNetwork}
      />
      <div className="main-screen">
        <Outlet context={[sideBarOpen, setSideBarOpen, network, setNetwork]} />
      </div>
    </main>
  );
};
export default Home;
