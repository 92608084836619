import HomeIcon from "@mui/icons-material/HomeOutlined";
import PaymentsIcon from "@mui/icons-material/PaymentsOutlined";
import PersonIcon from "@mui/icons-material/Person";
import CalculateIcon from "@mui/icons-material/Calculate";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import NotificationsIcon from "@mui/icons-material/NotificationsActiveOutlined";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import BadgeIcon from "@mui/icons-material/Badge";

export const pages = [
  {
    name: "Dashboard",
    path: "/Dashboard",
    icon: HomeIcon,
  },
  {
    name: "Payroll Management",
    path: "/PayrollManagement",
    icon: PaymentsIcon,
  },
  {
    name: "Employee Management",
    path: "/EmployeeManagement",
    icon: PersonIcon,
  },
  {
    name: "Finances",
    path: "/Finances",
    icon: CalculateIcon,
  },
  {
    name: "Time Tracking",
    path: "/TimeTracking",
    icon: PunchClockIcon,
  },
  {
    name: "Employee Self Service",
    path: "/EmployeeSelfService",
    icon: BadgeIcon,
  },
  {
    name: "Team Calendar",
    path: "/TeamCalender",
    icon: CalendarIcon,
  },
  {
    name: "Notification",
    path: "/Notification",
    icon: NotificationsIcon,
  },
];

// export const api = "https://rude-zebras-wash-139-59-10-89.loca.lt";
// export const api = "https://large-melons-drum-139-59-10-89.loca.lt";
// export const api = "https://yellow-birds-wait-139-59-10-89.loca.lt";
// export const api = 'https://violet-pots-sniff-139-59-10-89.loca.lt'
export const  api = 'https://metal-walls-bow-139-59-10-89.loca.lt'; 

export const networks = [
  { name: "Network", value: "network" },
  { name: "Aptos", value: "mawb" },
  { name: "Near", value: "nwb" },
  { name: "SUI", value: "suib" },
];
