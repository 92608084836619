import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { Button, Input } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { MobileDatePicker } from "@mui/x-date-pickers";
import axiosRequest from "../../../../../utils/reqests";
import { getSession } from "./../../../../../utils/reqests";
import convertTZ from "../../../../../utils/tzConverter";

const Schadule = ({ screenWidth, file, network }) => {
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState("");
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");

  const smallScreen = screenWidth < 600;

  const saveSchadule = (timePhrase, time) => {
    const currentSession = JSON.parse(localStorage.getItem("session"))[network];
    const schaduledSession = {
      id: currentSession.id,
      time: timePhrase,
      timeLeft: time,
    };
    const currentSchadule = JSON.parse(
      localStorage.getItem(`schadule-${network}`)
    );
    if (currentSchadule) {
      currentSchadule.push(schaduledSession);
      localStorage.setItem(
        `schadule-${network}`,
        JSON.stringify(currentSchadule)
      );
    } else {
      localStorage.setItem(
        `schadule-${network}`,
        JSON.stringify([schaduledSession])
      );
    }
  };
  const schadule = async () => {
    setLoading(true);

    // add the hours to the date
    date.setHours(parseInt(hours));
    // add the minutes to the date
    date.setMinutes(parseInt(minutes));

    // convert it to the correct time zone
    const convertedDate = convertTZ(date);

    const year = convertedDate.getFullYear();

    let month = parseInt(convertedDate.getMonth()) + 1;
    month = month < 10 ? "0" + month : month;

    const convertedHours =
      convertedDate.getHours() < 10
        ? "0" + convertedDate.getHours()
        : convertedDate.getHours();

    const convertedMinutes =
      convertedDate.getMinutes() < 10
        ? "0" + convertedDate.getMinutes()
        : convertedDate.getMinutes();

    const day =
      convertedDate.getDate() < 10
        ? `0${convertedDate.getDate()}`
        : convertedDate.getDate();

    let formdata = new FormData();
    formdata.append("file", file);

    let bodyContent = formdata;

    const url =
      `/${network}/upload/?` +
      new URLSearchParams({
        sched_time: `${year}-${month}-${day} ${convertedHours}:${convertedMinutes}`,
      }).toString();

    let reqOptions = {
      url: url,
      method: "POST",
      data: bodyContent,
    };

    try {
      let response = await axiosRequest[network].request(reqOptions);
      console.log(response);
      setResults(response.data.stauts);
      setLoading(false);
      saveSchadule(response.data.stauts, convertedDate.getTime());
      setTimeout(() => {
        setResults("Getting a new session, please wait");
      }, 5000);
      const session = await getSession(network);
      setResults("New session acquired, reloading page");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="responsive-row">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {smallScreen ? (
          <MobileDatePicker
            label="Date"
            value={date}
            onChange={(newValue) => {
              setDate(newValue.$d);
            }}
            renderInput={(params) => <TextField {...params} />}
            className="schadule"
            disablePast
          />
        ) : (
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            openTo="day"
            value={date}
            onChange={(newValue) => {
              setDate(newValue.$d);
            }}
            renderInput={(params) => <TextField {...params} />}
            disablePast
            className="schadule"
            sx={{
              width: "100%",
            }}
          />
        )}
      </LocalizationProvider>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "wrap",
          padding: "5%",
        }}
      >
        <div className="time-box">HH</div>
        <div className="time-box">MM</div>
        <div className="time-box">SS</div>
        <span className="time-box">
          <Input
            sx={{
              color: "white",
            }}
            value={hours}
            onChange={(e) => {
              if (isNaN(e.target.value)) {
                return;
              } else if (
                e.target.value === "" ||
                e.target.value === " " ||
                e.target.value == 0
              ) {
                setHours("00");
                return;
              } else {
                let value = parseInt(e.target.value);
                if (value > 24) {
                  value = 24;
                } else if (value < 0) {
                  value = 0;
                }
                if (value < 10) {
                  value = "0" + value;
                }
                setHours(value);
              }
            }}
          ></Input>
        </span>
        <span className="time-box">
          <Input
            sx={{
              color: "white",
            }}
            value={minutes}
            onChange={(e) => {
              if (isNaN(e.target.value)) {
                return;
              } else if (
                e.target.value === "" ||
                e.target.value === " " ||
                e.target.value == 0
              ) {
                setMinutes("00");
                return;
              } else {
                let value = parseInt(e.target.value);
                if (value > 60) {
                  value = 60;
                } else if (value < 0) {
                  value = 0;
                }
                if (value < 10) {
                  value = "0" + value;
                }
                setMinutes(value);
              }
            }}
          ></Input>
        </span>
        <span className="time-box">
          <Input
            sx={{
              color: "white",
            }}
            defaultValue={"00"}
            disabled
          ></Input>
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "1em",
          backgroundColor: "#1d1933",
          borderRadius: ".5em",
          padding: "2em",
        }}
      >
        <h4>Picked Time</h4>
        <p
          style={{
            width: "70%",
            textAlign: "center",
          }}
        >
          {date.toDateString()}
          <br />
          {hours}:{minutes}:00
        </p>
        <Button
          variant="contained"
          disabled={!file}
          className="main-button"
          onClick={schadule}
        >
          Submit
        </Button>
        <LoadingButton loading={loading} />
        {results && (
          <div>
            <h4>Results</h4>
            <p>{results}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Schadule;
