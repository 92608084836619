import FileIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { IconButton } from "@mui/material";

const FilesUploaded = ({ files, removeFile, smallScreen }) => {
  return files.map((file, index) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: !smallScreen ? "0em 1.5em" : "0",
          fontSize: !smallScreen ? "1em" : ".8em",
        }}
        key={file}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <FileIcon
            sx={{
              color: "gray",
              fontSize: "2em",
            }}
          />
          <span>{file.name}</span>
          <span
            style={{
              color: "gray",
              fontSize: ".7em",
            }}
          >
            {file.size}
          </span>
        </div>
        <IconButton
          sx={{
            color: "white",
          }}
          {...removeFile(index)}
        >
          <CloseIcon />
        </IconButton>
      </div>
    );
  });
};

export default FilesUploaded;
