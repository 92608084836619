import { Button } from "@mui/material";
import uploadIcon from "../../../../../assest/images/upload-icon.svg";
import { useEffect, useState } from "react";
import FilesUploaded from "./filesUploaded";
import Preview from "./preview";
import { useCSVReader } from "react-papaparse";

const UploadSVG = ({ screenWidth, setFile, setProcessing }) => {
  const { CSVReader } = useCSVReader();
  const [filesToPreview, setFilesToPreview] = useState([]);
  const [preview, setPreview] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const smallScreen = screenWidth < 600;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
        width: "100%",
        gap: "2em",
      }}
    >
      <CSVReader
        onUploadAccepted={(results, originalFile) => {
          setFilesToPreview(results.data);
          setFile(originalFile);
          setDragOver(false);
        }}
        onDragEnter={() => {
          setDragOver(true);
        }}
      >
        {({ getRootProps, acceptedFile, getRemoveFileProps }) => (
          <>
            <h3 style={{ width: "100%", textAlign: "left" }}>
              Upload CSV File
            </h3>
            <div
              style={{
                width: "100%",
                backgroundColor: dragOver ? "rgb(51 44 86)" : "#1D1933",
                borderRadius: ".25em",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: !smallScreen ? "wrap" : "nowrap",
                padding: "1em 1.5em",
                cursor: "pointer",
                gap: "1em",
              }}
              {...getRootProps()}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "2em",
                  textAlign: !smallScreen ? "center" : "left",
                  flexWrap: "wrap",
                }}
              >
                <img
                  src={uploadIcon}
                  alt="upload icon"
                  style={{ width: "2em", height: "2em" }}
                />
                {!smallScreen && (
                  <div
                    style={{
                      fontWeight: "lighter",
                      fontSize: ".8em",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1.2em",
                        fontWeight: "400",
                      }}
                    >
                      Drag and drop your file here
                    </span>
                    <br />
                    <span
                      style={{
                        fontSize: ".8em",
                      }}
                    >
                      Limit 200 MB per file
                    </span>
                  </div>
                )}
              </div>

              <Button
                style={{
                  backgroundColor: "#BB46E3",
                }}
                className="main-button"
              >
                Browse Files
              </Button>
            </div>
            <FilesUploaded
              files={acceptedFile ? [acceptedFile] : []}
              removeFile={getRemoveFileProps}
              smallScreen={smallScreen}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {!smallScreen && (
                <Button
                  style={{
                    backgroundColor: "#BB46E3",
                  }}
                  className="main-button"
                  type="file"
                  onClick={() => {
                    setPreview(!preview);
                  }}
                >
                  Preview
                </Button>
              )}
              <Button
                sx={{
                  color: "#bb46e3",
                  textDecoration: "underline",
                }}
                variant="text"
                onClick={() => {
                  setProcessing(true);
                }}
              >
                procces
              </Button>
            </div>

            <Preview preview={preview} fileData={filesToPreview} />
          </>
        )}
      </CSVReader>
    </div>
  );
};

export default UploadSVG;
