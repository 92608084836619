import { useState, useEffect, useRef } from "react";
import axiosRequest from "../../../../../utils/reqests";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { api } from "../../../../../vars";
import { Button } from "@mui/material";

const ProgressScreen = ({
  file,
  setProcessing,
  setOnGoingRequest,
  onGoingRequest,
  network,
}) => {
  const [progress, setProgress] = useState("");
  const [img, setImg] = useState("");
  const [stage, setStage] = useState(1);
  const [stages, Setstages] = useState({
    1: -100,
    2: -100,
    3: -100,
    4: -100,
    5: -100,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [completionFile, setCompletionFile] = useState("");

  const stage1 = useRef();
  const stage2 = useRef();
  const stage3 = useRef();
  const stage4 = useRef();
  const stage5 = useRef();

  const getMid = (elm) => {
    const rect = elm.current.getBoundingClientRect();
    return rect.top + rect.height / 2;
  };
  useEffect(() => {
    Setstages({
      // get the middle of the element by getBoundingClientRect
      1: getMid(stage1),
      2: getMid(stage2),
      3: getMid(stage3),
      4: getMid(stage4),
      5: getMid(stage5),
    });
  }, [stage]);

  const setUi = (state) => {
    if (state === "uploading excel") {
      setStage(2);
      setProgress("");
    } else if (state === "excel process started") {
      setStage(3);
      setProgress("");
    } else if (state.includes("processed")) {
      setStage(4);
      setProgress(state);
    } else if (state.includes("complete")) {
      setStage(5);
    } else {
      setProgress(state);
    }
  };

  const processData = async (file) => {
    let formdata = new FormData();
    formdata.append("file", file);

    let bodyContent = formdata;

    let reqOptions = {
      url: `/${network}/upload/`,
      method: "POST",
      data: bodyContent,
    };

    let response = await axiosRequest[network].request(reqOptions);
    console.log(response);
    return response.data;
  };

  useEffect(() => {
    const getProgress = async () => {
      try {
        const res = await axiosRequest[network].get(`/${network}/prgs/`);
        setUi(res.data.progress);
        console.log(res.data.progress);
      } catch (error) {
        console.log(error);
        if (error.response.data.progress === "session expired") {
          setProgress("session expired Please login again");
          setOnGoingRequest(false);
          clearInterval(progressInterval);
          setTimeout(() => {
            window.location.href = "/login";
          }, 3000);
        }
      }
    };
    getProgress();
    const progressInterval = setInterval(getProgress, 5000);
    (async () => {
      try {
        setOnGoingRequest(true);
        const proccessData = await processData(file);
        setProgress(proccessData.status);
        clearInterval(progressInterval);
        setOnGoingRequest(false);
        setUi(proccessData.status);
        setCompletionFile(proccessData.pf);
      } catch (err) {
        console.log(err);
        clearInterval(progressInterval);
        setProgress("Error Occured, Please check the image above");
        setImg(err.response.data.err_img);
        setOnGoingRequest(false);
        if (!err.response.data.err_img) {
          setProgress("");
          setErrorMsg("Unknown Error Occured, Please try again later");
          setOnGoingRequest(false);
        }
      }
    })();

    return () => {
      clearInterval(progressInterval);
    };
  }, [file, setProcessing]);

  return (
    <div className="progress-screen">
      <IconButton
        sx={{
          position: "fixed",
          top: "1em",
          right: "1em",
          color: "white",
        }}
        onClick={() => setProcessing(false)}
        disabled={onGoingRequest}
      >
        <CancelOutlinedIcon />
      </IconButton>

      {img && (
        <img
          src={api + img}
          alt="Erorr Image"
          onClick={() => {
            window.open(api + img);
          }}
          style={{
            cursor: "pointer",
          }}
        />
      )}
      {errorMsg && <h2>{errorMsg}</h2>}
      <h1>Statges</h1>
      <div className="stages">
        <span
          className="stage"
          style={{
            top: `${stages[stage]}px`,
          }}
        ></span>
        <div
          className={"progress" + (stage > 1 ? " state-done" : "")}
          ref={stage1}
        >
          Getting Ready
        </div>
        <div
          className={"progress" + (stage > 2 ? " state-done" : "")}
          ref={stage2}
        >
          Upload
        </div>
        <div
          className={"progress" + (stage > 3 ? " state-done" : "")}
          ref={stage3}
        >
          Processing Excel File
        </div>
        <div
          className={"progress" + (stage > 4 ? " state-done" : "")}
          ref={stage4}
        >
          Started Excuting : {progress}
        </div>
        <div
          className={"progress" + (stage === 5 ? " state-done" : "")}
          ref={stage5}
        >
          Done, You can Now Exit
        </div>
        {completionFile && (
          <Button
            variant="contained"
            onClick={() => window.open(api + completionFile)}
          >
            Download Completion File
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProgressScreen;
