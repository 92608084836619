import { api } from "../vars";
import axios from "axios";
import convertTZ from "./tzConverter";
import { networks } from "../vars";

const axiosRequest = {};
networks.forEach((network) => {
  axiosRequest[network.value] = axios.create({
    baseURL: api,
  });
});

let session = JSON.parse(localStorage.getItem("session"));
if (session && session["mawb"] && session["nwb"] && session["suib"]) {
  axiosRequest.mawb.defaults.headers.common["X-Request-ID"] =
    session["mawb"].id;

  axiosRequest.nwb.defaults.headers.common["X-Request-ID"] = session["nwb"].id;

  axiosRequest.suib.defaults.headers.common["X-Request-ID"] =
    session["suib"].id;
}

export const getSession = async (network) => {
  let formdata = new FormData();

  if (network === "mawb") {
    formdata.append(
      "pp",
      "write resemble sample unaware width local foot into brass speak pole desert"
    );
    formdata.append("pwd", "tej12345");
  } else if (network === "nwb") {
    formdata.append(
      "pp",
      "output deputy crouch venue practice bomb movie section fruit market person initial"
    );
  } else if (network === "suib") {
    formdata.append(
      "pp",
      "novel mansion decide discover ice genius slender indicate bid immune access title"
    );
    formdata.append("pwd", "yajiv0908");
  }

  let bodyContent = formdata;

  let reqOptions = {
    url: `${api}/${network}/pp/`,
    method: "POST",
    data: bodyContent,
  };
  try {
    let response = await axiosRequest[network].request(reqOptions);
    console.log(response);
    // set the session as axios default header
    const session = {
      time: convertTZ(new Date()).getTime(),
      id: response.data.session,
    };
    let currentSession = localStorage.getItem("session");
    currentSession = JSON.parse(currentSession);
    currentSession[network] = session;
    localStorage.setItem("session", JSON.stringify(currentSession));
    axiosRequest[network].defaults.headers.common["X-Request-ID"] =
      response.data.session;
    return response.data.session;
  } catch (error) {
    console.log(error);
    throw new Error(error.response.data);
  }
};

export default axiosRequest;
