const convertTZ = (date) => {
  const newDate = new Date(date);
  const offset = newDate.getTimezoneOffset();
  const offsetInMs = offset * 60 * 1000;
  newDate.setHours(date.getHours() + 5);
  newDate.setMinutes(date.getMinutes() + 30);
  newDate.setTime(newDate.getTime() + offsetInMs);
  return newDate;
};
export default convertTZ;
