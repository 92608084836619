import { useState, useEffect } from "react";
import ScheduledSessionManger from "./scheduledSessionManger";
import { useOutletContext } from "react-router";

const SchaduledSessions = () => {
  const [sessions, setSessions] = useState([]);
  const [network] = useOutletContext();

  useEffect(() => {
    let schaduledSessions = JSON.parse(
      localStorage.getItem(`schadule-${network}`)
    );
    if (schaduledSessions) {
      setSessions(schaduledSessions);
    } else {
      setSessions([]);
    }
  }, [network]);

  if (sessions.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1em",
        flexWrap: "wrap",
      }}
    >
      <div style={{ width: "100%" }}>
        <h3>Schaduled Sessions</h3>
      </div>
      {sessions.map((session, index) => (
        <ScheduledSessionManger
          key={index}
          session={session}
          sessions={sessions}
          setSessions={setSessions}
          network={network}
        />
      ))}
    </div>
  );
};

export default SchaduledSessions;
