import logo from "../../assest/images/logo-with-text.png";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import convertTZ from "./../../utils/tzConverter";
import { networks } from "../../vars";

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // if there's a session , redirect to dashboard
    const session = localStorage.getItem("session");
    if (session) {
      navigate("/");
    }
  }, []);

  return (
    <main>
      <div
        style={{
          display: "grid",
          placeItems: "center",
          gap: "3rem",
        }}
      >
        <img src={logo} alt="LOGO" />
        <span
          style={{
            textAlign: "center",
            fontWeight: "300",
          }}
        >
          Enterprise Management Suite <br /> <br /> for Web3 Projects
        </span>
        <Button
          variant="contained"
          className={"main-button"}
          onClick={() => {
            let date = new Date();
            date = convertTZ(date);
            date.setMinutes(date.getMinutes() - 15);
            const session = {};
            networks.forEach((network) => {
              session[network.value] = {
                id: "123",
                time: date.getTime(),
              };
            });

            localStorage.setItem("session", JSON.stringify(session));
            if (window.location.href.includes("localhost")) {
              window.location.href =
                "https://discord.com/api/oauth2/authorize?client_id=1020439255996186704&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F&response_type=code&scope=identify%20email";
            } else {
              window.location.href =
                "https://discord.com/api/oauth2/authorize?client_id=1020439255996186704&redirect_uri=https%3A%2F%2Fvision-ff984.web.app%2F&response_type=code&scope=identify%20email%20connections";
            }
          }}
        >
          Login with Discord
        </Button>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "5%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        Powered by NEARverse Labs
      </div>
    </main>
  );
};
export default Login;
