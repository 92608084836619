import { useState, useEffect } from "react";
import axiosRequest from "../../utils/reqests";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { getSession } from "./../../utils/reqests";
import convertTZ from "../../utils/tzConverter";
import DropDown from "../../utils/dropdown";
import { networks } from "../../vars";

const SessionManger = ({ network, setNetwork }) => {
  const [sessionIsValid, setSessionIsValid] = useState(true);
  const [phrase, setPhrase] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);

  let session = localStorage.getItem("session");
  if (session) {
    session = JSON.parse(session);
  } else {
    session = {
      id: "",
      time: 0,
    };
  }

  const checkSession = async () => {
    setSessionIsValid(false);
    try {
      const res = await axiosRequest[network].get(`/${network}/prgs/`);
      console.log(res.data);
      setSessionIsValid(true);
    } catch (error) {
      console.log(error);
      if (error.response.data.error === "session expired") {
        setSessionIsValid(false);
        setPhrase("Session Expired Getting A New One");
        try {
          const newSession = await getSession(network);
          setPhrase("New Session Acquired Reloading Page");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } catch (error) {
          console.log(error);
          setPhrase("Error Getting New Session, Please Try Again Later");
        }
      } else {
        setPhrase(
          "Error Checking the currest session status, Getting a new Session"
        );
        setSessionIsValid(false);
        try {
          const newSession = await getSession(network);
          setPhrase("New Session Acquired Reloading Page");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } catch (error) {
          console.log(error);
          setPhrase("Error Getting New Session, Please Try Again Later");
        }
      }
    }
  };

  useEffect(() => {
    if (network === "network") {
      return;
    }

    const sessionObj = JSON.parse(localStorage.getItem("session"));
    const sessionTime = parseInt(sessionObj[network].time);
    const timeNow = convertTZ(new Date()).getTime();
    let timeDiffrance = 15 * 60 * 1000 - (timeNow - sessionTime);
    timeDiffrance = timeDiffrance < 0 ? (timeDiffrance = 1) : timeDiffrance;
    if (timeDiffrance > 0) {
      setTimeout(async () => {
        if (network === "network") {
          return;
        }
        try {
          setPhrase("Session Expired Getting A New One");
          const session = await getSession(network);
          setPhrase("New Session Acquired Reloading Page");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } catch (err) {
          setPhrase("Error Getting New Session, Please Try Again Later");
        }
      }, timeDiffrance);
    }

    const calcTimeLeft = () => {
      const timeNow = convertTZ(new Date()).getTime();
      const timeDiffrance = timeNow - sessionTime;
      const minutesLeft = 15 - Math.floor(timeDiffrance / 60000);
      setTimeLeft(minutesLeft);
    };

    calcTimeLeft();
    const interval = setInterval(calcTimeLeft, 10000);
    checkSession();
    return () => {
      clearInterval(interval);
    };
  }, [network]);

  if (network === "network") {
    return (
      <DropDown options={networks} setValue={setNetwork} value={network} />
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: ".4rem",
      }}
    >
      {sessionIsValid ? (
        <CheckIcon
          sx={{
            color: "#bb46e3",
          }}
        />
      ) : (
        <ClearIcon
          sx={{
            color: "#bb46e3",
          }}
        />
      )}
      <LoadingButton
        loading={!sessionIsValid}
        variant="outlined"
        onClick={checkSession}
        sx={{
          color: "#bb46e3",
          borderColor: "#bb46e3",
          backgroundColor: !sessionIsValid
            ? "rgba(255,255,255,0.4)"
            : "transparent",
        }}
      >
        Check Session
      </LoadingButton>

      <h3
        style={{
          textAlign: "center",
        }}
      >
        {phrase}
      </h3>

      {/* <h3
        style={{
          textAlign: "center",
          color: "#bb46e3",
        }}
      >
        Time Left {timeLeft - 1} Minutes
      </h3> */}
    </div>
  );
};

export default SessionManger;
